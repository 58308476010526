import React, { useEffect, useRef, useState } from 'react'
import { RichText } from 'prismic-reactjs'
import normalizeWheel from '../helpers/normalizeWheel'
import limitNumberWithinRange from '../helpers/limitNumberWithinRange'
import { GlobalEventBus } from '../helpers/eventBus'
import lerp from '../helpers/lerp'
import SplitText from './SplitText'
import Contact from './Contact'

const SliderScroll = ({ items, contactData, onProjectClick }) => {

	const [currentIndex, setCurrentIndex] = useState(0)

	// Elements
	const scrollerImagesEl = useRef(null)
	const imageMaskEls = useRef([])
	const imageContainerEls = useRef([])
	const scrollerTextsEl = useRef(null)
	const textsEls = useRef([])
	const progressBarEl = useRef(null)

	// Variables
	const speed = useRef(0)
	const scrollerX = useRef(0)
	const targetScrollerX = useRef((items.length - 1) * -100)
	const scrollerMaxX = useRef(0)
	const scrollerContactX = useRef(0)
	const lastTouch = useRef(0)
	const textElTranslationRatio = useRef(0)
	const lerpValue = useRef(1)

	/**
	*	Init anim - Allows to load all images on mobile
	*/
	useEffect(() => {
		setTimeout(() => {
			lerpValue.current = 0.075
			targetScrollerX.current = 0
		}, 10);
	}, [])

	/**
	*	Handle wheel
	*/
	useEffect(() => {

		let timeoutSpeed = null
		let timeoutGoToClosest = null

		const handleWheel = e => {
			const normalizedEvent = normalizeWheel(e)
			speed.current = limitNumberWithinRange(normalizedEvent.pixelY, -60, 60)
			targetScrollerX.current = scrollerX.current - speed.current
			scrollerTextsEl.current.classList.add('isScrolling')
			if (targetScrollerX.current > 0) {
				scrollerTextsEl.current.classList.remove('isScrolling')
				targetScrollerX.current = 0
			}
			else if (targetScrollerX.current < scrollerMaxX.current) {
				targetScrollerX.current = scrollerMaxX.current
			}
			clearTimeout(timeoutSpeed)
			timeoutSpeed = setTimeout(() => {
				speed.current = 0
				clearTimeout(timeoutGoToClosest)
				timeoutGoToClosest = setTimeout(() => {
					scrollerTextsEl.current.classList.remove('isScrolling')
					goToClosest()
				}, 200);
			}, 50)
		}

		window.addEventListener('wheel', handleWheel)
		return () => {
			window.removeEventListener('wheel', handleWheel)
		}
	}, [])

	/**
 	*	Handle touch
	*/
	useEffect(() => {

		let timeoutGoToClosest = null

		const handleTouchStart = e => {
			lastTouch.current = e.touches[0].screenX + e.touches[0].screenY
			scrollerTextsEl.current.classList.add('isScrolling')
		}

		const handleTouchEnd = () => {
			scrollerTextsEl.current.classList.remove('isScrolling')
		}

		const handleTouchMove = e => {
			const movement = lastTouch.current - (e.touches[0].screenX + e.touches[0].screenY)
			targetScrollerX.current = scrollerX.current - movement * 4
			if (targetScrollerX.current > 0) {
				scrollerTextsEl.current.classList.remove('isScrolling')
				targetScrollerX.current = 0
			}
			else if (targetScrollerX.current < scrollerMaxX.current) {
				targetScrollerX.current = scrollerMaxX.current
			}
			lastTouch.current = e.touches[0].screenX + e.touches[0].screenY
			clearTimeout(timeoutGoToClosest)
			timeoutGoToClosest = setTimeout(() => {
				scrollerTextsEl.current.classList.remove('isScrolling')
				goToClosest()
			}, 400);
		}

		window.addEventListener('touchstart', handleTouchStart)
		window.addEventListener('touchend', handleTouchEnd)
		window.addEventListener('touchmove', handleTouchMove)

		return () => {
			window.removeEventListener('touchstart', handleTouchStart)
			window.removeEventListener('touchend', handleTouchEnd)
			window.removeEventListener('touchmove', handleTouchMove)
		}

	}, [])

	const goToClosest = () => {
		const closestIndex = Math.round(scrollerX.current / 100)
		setCurrentIndex(Math.abs(closestIndex))
		targetScrollerX.current = closestIndex * 100
	}

	/**
	*	Resize
	*/
	useEffect(() => {

		const handleResize = () => {
			const ww = window.innerWidth
			scrollerMaxX.current = items.length * -100
			scrollerContactX.current = scrollerMaxX.current + 50
			textElTranslationRatio.current = ww >= 1024 ? 0.9 : 0.6
		}

		handleResize()
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	/**
	*	Request animation frame
	*/
	useEffect(() => {

		let rafId = null

		const tick = () => {
			// Update scroller x
			scrollerX.current = lerp(scrollerX.current, targetScrollerX.current, lerpValue.current)

			// Transformations
			scrollerImagesEl.current.style.transform = `translate3d(${scrollerX.current}vw, 0, 0)`
			scrollerTextsEl.current.style.transform = `translate3d(${scrollerX.current}vw, 0, 0)`
			for (let i = 0; i < items.length; i += 1) {
				const beginX = 100 * i
				const endX = beginX + 100
				const slideProgressX = (scrollerX.current + beginX)
				imageMaskEls.current[i].style.transform = `translate3d(${-slideProgressX * 0.5}vw, 0, 0)`
				textsEls.current[i].style.transform = `translate3d(${(slideProgressX * textElTranslationRatio.current) * -0.5}vw, 0, 0)`
				textsEls.current[i].style.opacity = (1 - Math.abs(slideProgressX * 0.01)) * 0.5 + 0.5
			}
			const progress = scrollerX.current / scrollerMaxX.current
			progressBarEl.current.style.transform = `scaleX(${progress * 0.9 + 0.1})`

			// Detect if on contact for nav color
			if (navColor.current !== 'black' && scrollerX.current <= scrollerContactX.current) {
				GlobalEventBus.emit('navColorChange', { newColor: 'black' })
			} else if (navColor.current !== 'white' && scrollerX.current > scrollerContactX.current) {
				GlobalEventBus.emit('navColorChange', { newColor: 'white' })
			}

			rafId = window.requestAnimationFrame(tick)
		}

		tick()

		return () => {
			window.cancelAnimationFrame(rafId)
		}
	}, [])

	/**
 	*	Go to contact
	*/
	useEffect(() => {
		const handleGoToContact = () => {
			targetScrollerX.current = scrollerMaxX.current
		}
		GlobalEventBus.on('homeGoToContact', handleGoToContact)
		return () => {
			GlobalEventBus.off('homeGoToContact', handleGoToContact)
		}
	}, [])

	/**
 	*	Go to start
	*/
	useEffect(() => {
		const handleGoToStart = () => {
			targetScrollerX.current = 0
		}
		GlobalEventBus.on('homeGoToStart', handleGoToStart)
		return () => {
			GlobalEventBus.off('homeGoToStart', handleGoToStart)
		}
	}, [])

	/**
	*	Get nav color at the init and on change
	*/
	const navColor = useRef(null)
	useEffect(() => {
		const handleNavColorChange = e => {
			navColor.current = e.detail.newColor
		}
		GlobalEventBus.on('navColorChange', handleNavColorChange)
		return () => {
			GlobalEventBus.off('navColorChange', handleNavColorChange)
		}
	}, [])

	return (
		<div className="SliderScroll">
			<div className="SliderScroll__ScrollerImages" ref={scrollerImagesEl}>
				{items.map((item, index) => (
					<div className="SliderScroll__Image" key={`slider-scroll-image-${index}`}>
						<div
							className="SliderScroll__ImageMask"
							ref={ref => imageMaskEls.current[index] = ref}
						>
							<img
								className="SliderScroll__ImageContainer"
								ref={ref => imageContainerEls.current[index] = ref}
								alt={item.project.document.data.image.alt}
								src={item.project.document.data.image.fluid.src}
								srcSet={item.project.document.data.image.fluid.srcSet}
							/>
						</div>
					</div>
				))}
				<div className="SliderScroll__Contact">
					<Contact data={contactData}/>
					</div>
			</div>
			<div className="SliderScroll__ScrollerTexts" ref={scrollerTextsEl}>
				{items.map((item, index) => (
					<div
						className={`SliderScroll__ScrollerText ${currentIndex === index ? 'isCurrent' : ''} ${item.project.document.data.is_client_on_two_lines === true ? 'isClientOnTwoLines' : ''}`}
						key={`slider-scroll-text-${index}`}
						ref={ref => textsEls.current[index] = ref}
					>
						<div className="SliderScroll__ScrollerTextContainer">
							<div
								className="SliderScroll__ScrollerTextTitle"
								onClick={() => onProjectClick(item)}
							>
								<RichText render={item.project.document.data.client.richText}/>
							</div>
							<div className={`SliderScroll__ScrollerTextMovie ${item.project.document.data.is_title_lower === true ? 'isTitleLower' : ''}`}>
								<SplitText hasMask>
									<RichText render={item.project.document.data.title.richText}/>
								</SplitText>
							</div>
						</div>
					</div>
				))}
			</div>
			<div
				className="SliderScroll__ProgressBar"
				ref={progressBarEl}
			></div>
		</div>
	)
}

export default SliderScroll