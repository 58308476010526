import React, { useContext, useEffect, useState } from "react"
import { graphql } from 'gatsby'
import { ContextLoader } from '../context'
import SliderScroll from '../components/SliderScroll'
import VimeoPlayer from '../components/VimeoPlayer'
import { GlobalEventBus } from '../helpers/eventBus'
import { detectElementsOnScreen, killObservers } from '../helpers/detectElementsOnScreen'
import Seo from "../components/Seo"

const Homepage = ({ data }) => {
	const pageData = data.prismicHomepage.data

	/**
 	*	Detect elements on screen
	*/
	const [isLoading] = useContext(ContextLoader);
	useEffect(() => {
		if (isLoading === true) return
		const [observers, elements] = detectElementsOnScreen()
		return () => {
			killObservers(observers, elements)
		}
	}, [isLoading])

	/**
 	*	Color
	*/
	useEffect(() => {
		GlobalEventBus.emit('navColorChange', { newColor: 'white' })
	}, [])

	/**
 	*	Player and project click
	*/
	const [selectedProject, setSelectedProject] = useState(null)
	const handleProjectClick = data => {
		const project = data.project.document.data
		if (project.vimeo_id.length === 0) {
			return
		}
		setSelectedProject(project)
	}

	const handlePlayerClose = () => {
		setSelectedProject(null)
	}

  return (
    <main className="Homepage">
			<Seo
				description='Independant Production Company Focused on Delivering Inspiring and Well- Crafted Content Across Advertising, Branded Content, Short Fiction and Documentary'
			/>
			<SliderScroll
				items={pageData.projects}
				contactData={data.prismicContact.data}
				onProjectClick={handleProjectClick}
			/>
			<VimeoPlayer
				project={selectedProject}
				onClose={handlePlayerClose}
			/>
    </main>
  )
}

export default Homepage

export const query = graphql`
	query Homepage {
		prismicHomepage {
			data {
				projects {
					project {
						document {
							... on PrismicProject {
								data {
									client {
										text
										richText
									}
									title {
										richText
										text
									}
									is_title_lower
									is_client_on_two_lines
									image {
										alt
										fluid {
											src
											srcSet
										}
									}
									vimeo_id {
										text
									}
									production_company {
										text
									}
									director {
										text
									}
								}
							}
						}
					}
				}
			}
		}
		prismicNav {
			...Nav
		}
		prismicContact {
      ...Contact
    }
	}
`